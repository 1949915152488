<template>
  <div class="bg-white mt-5 p-5">
    <el-form
      label-position="left"
      size="medium"
      label-width="80px"
      :model="formData"
      :rules="rules"
      ref="form"
    >
      <el-form-item
        label="广告账户"
        prop="adAccountId"
      >
        <selectAccount
          v-model="formData.adAccountId"
          platform="snapchat"
          @change="onChangeAdAccountId"
          @getAllList="(list) => $emit('getAllAdAccountId', list)"
        ></selectAccount>
      </el-form-item>
      <el-form-item
        label="推广连接"
        prop="promotionLink"
      >
        <el-input
          v-model="formData.promotionLink"
          @blur="onChangepromotionLink"
          v-loading="promotionLinkLoading"
          @keyup.enter.native="
            (e) => {
              e.target.blur();
            }
          "
        ></el-input>
      </el-form-item>
      <el-form-item
        label="商品id"
        prop="sale"
      >
        <sale :sale-info="formData.sale"></sale>
      </el-form-item>
      <el-form-item
        label="站点信息"
        prop="site"
      >
        <site
          v-model="formData.site"
          disabled
        ></site>
      </el-form-item>
      <el-form-item
        label="像素"
        prop="pixelId"
        v-loading="loadingPixel"
      >
        <el-select
          v-model="formData.pixelId"
          @change="onChange"
        >
          <el-option
            v-for="pixel in pixelList"
            :key="pixel.id"
            :value="pixel.id"
            :label="pixel.name"
          ></el-option>
        </el-select>
        <el-button
          type="text"
          icon="el-icon-refresh"
          @click="refresPixel"
        ></el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import selectAccount from '@/components/create/selectAccount.vue';
import sale from '@/components/create/sale.vue';
import site from '@/components/create/site.vue';
import { promotionInfo, getAccountPixels, refreshAccountPixels } from '@/api/multiPlatform';
import { rules, regUrl } from './index';

export default {
  props: {
    value: Object,
  },
  components: {
    selectAccount,
    sale,
    site,
  },
  data() {
    return {
      promotionLinkLoading: false,
      formData: {
        promotionLink:
          'https://iebonshop.com/index/detail.html?sno=MTAwNzAyOTUwMg==&coll_id=1001960881&cstpzptiirbjfc=',
      },
      rules: rules,
      loadingPixel: false,
      pixelList: [],
    };
  },
  methods: {
    onChangepromotionLink() {
      if (!this.formData.promotionLink || !this.formData.promotionLink.trim()) return;
      let params = {
        url: this.formData.promotionLink.trim(),
      };
      if (!regUrl.test(this.formData.promotionLink)) {
        return;
      }
      if (this.value.promotionInfo.promotionLink == this.formData.promotionLink) return;

      this.promotionLinkLoading = true;
      this.$set(this.formData, 'sale', null);
      this.$set(this.formData, 'site', null);
      promotionInfo(params)
        .then((res) => {
          if (res.code == 0) {
            this.$set(this.formData, 'site', res.data.site);
            this.$set(this.formData, 'sale', res.data.sale);
          } else {
            this.$set(this.formData, 'sale', null);
            this.$set(this.formData, 'site', null);
          }
          this.onChange();
          this.$emit('changePromotionLink', this.formData.promotionLink);
        })
        .finally(() => {
          this.promotionLinkLoading = false;
        });
    },
    onChange() {
      const { adAccountId, promotionLink, sale, site, pixelId } = this.formData;
      const newValue = {
        ...this.value,
        adAccountId,
        pixelId,
        promotionInfo: {
          promotionLink,
          sale,
          site,
        },
      };
      this.$emit('input', {
        ...newValue,
      });
      this.$emit('change', {
        ...newValue,
      });
    },
    onChangeAdAccountId() {
      let params = {
        accountId: this.formData.adAccountId,
        platform: 'snapchat',
      };
      this.loadingPixel = true;
      this.$set(this.formData, 'pixelId', null);
      getAccountPixels(params)
        .then((res) => {
          if (res.code == 0) {
            this.pixelList = res.data;
          }
        })
        .finally(() => {
          this.loadingPixel = false;
        });
    },
    refresPixel() {
      let params = {
        accountId: this.formData.adAccountId,
        platform: 'snapchat',
      };
      this.loadingPixel = true;
      this.$set(this.formData, 'pixelId', null);
      refreshAccountPixels(params)
        .then((res) => {
          if (res.code == 0) {
            this.pixelList = res.data;
          }
        })
        .finally(() => {
          this.loadingPixel = false;
        });
    },
    // 对外提供的验证
    validate() {
      return this.$refs.form.validate();
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        const { adAccountId, promotionInfo, pixelId } = val;
        this.formData = {
          adAccountId,
          promotionLink: promotionInfo.promotionLink,
          sale: promotionInfo.sale,
          site: promotionInfo.site,
          pixelId,
        };
      },
    },
  },
};
</script>
